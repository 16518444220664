import { FC } from 'react'

import Slider from 'react-slick'

import { ContainerUI } from 'src/components/UiKit/Container'
import { CustomArrow } from 'src/components/UiKit/CustomArrow'

import style from './style.module.scss'

type Props = {
	images: string[]
}

export const SliderCars: FC<Props> = ({ images }) => {
	const settings = {
		className: style.sliderCars__slider,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		centerMode: true,
		swipeToSlide: true,
		speed: 500,
		touchThreshold: 10,
		centerPadding: '95px',
		arrows: true,
		dots: true,
		nextArrow: <CustomArrow direction='next' />,
		prevArrow: <CustomArrow direction='prev' />,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					centerPadding: '100px',
					arrows: false,
				},
			},
			{
				breakpoint: 670,
				settings: {
					centerPadding: '40px',
					arrows: false,
				},
			},
		],
	}

	return (
		<ContainerUI className={style.sliderCars}>
			<Slider {...settings}>
				{images.map((image, index) => (
					<div className={style.sliderCars__slide} key={index}>
						<img className={style.sliderCars__image} src={image} alt='Машина' />
					</div>
				))}
			</Slider>
		</ContainerUI>
	)
}
