import { ChangeEvent, forwardRef, HTMLAttributes, useState } from 'react'

import { FieldError } from 'react-hook-form'

import { ErrorMessage } from 'src/components/UiKit/ErrorMessage'

import { normalizeCountForm } from 'src/utils/normalizeCountForm'

import style from './style.module.scss'

type Props = HTMLAttributes<HTMLInputElement> & {
	labelText: string
	error: FieldError | undefined
	onChange: (value: string) => void
	id: string
	value?: string
}

export const InputOwnershipDuration = forwardRef<HTMLInputElement, Props>(
	({ labelText, error, onChange, id, value, ...args }, ref) => {
		const [duration, setDuration] = useState<string>('')

		const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value

			setDuration(value)
			onChange(value)
		}

		const monthsWord = normalizeCountForm(Number(duration), [
			'месяц',
			'месяца',
			'месяцев',
		])

		return (
			<div className={style.inputOwnershipDuration}>
				<input
					className={style.inputOwnershipDuration__input}
					type='number'
					inputMode='decimal'
					onChange={handleChange}
					placeholder=' '
					ref={ref}
					id={id}
					value={value}
					{...args}
				/>

				<label className={style.inputOwnershipDuration__label} htmlFor={id}>
					{labelText}
				</label>

				<span className={style.inputOwnershipDuration__months}>
					{duration && monthsWord}
				</span>

				<ErrorMessage
					className={style.inputOwnershipDuration__error}
					error={error}
				/>
			</div>
		)
	}
)

InputOwnershipDuration.displayName = 'InputOwnershipDuration'
