import { FC } from 'react'

import { useMediaQuery } from 'react-responsive'

import { Button } from 'src/components/UiKit/Button'

import classNames from 'classnames'
import { ContainerUI } from 'src/components/UiKit/Container'
import style from './style.module.scss'

const formSpreeAction = process.env.REACT_APP_FORMSPREE_OTHER_QUESTIONS || 'key'

export const LogoWrapper: FC = () => (
	<div className={style.footer__logoWrapper}>
		<ContainerUI className={style.footer__logo}>
			<p>Открытый гараж</p>
		</ContainerUI>
	</div>
)

export const FooterLogo: FC = () => (
	<footer className={classNames(style.footer, style.footerLogo)}>
		<LogoWrapper />
	</footer>
)

export const Footer: FC = () => {
	const isTablet = useMediaQuery({ maxWidth: 768 })

	return (
		<footer className={style.footer} id='footer'>
			<ContainerUI className={style.footer__questions}>
				<h2 className={style.footer__title}>Остались вопросы?</h2>

				<h3 className={style.footer__subtitle}>
					Оставьте свою почту и мы обязательно свяжемся с Вами
				</h3>

				<form
					className={style.footer__form}
					action={formSpreeAction}
					method='POST'
				>
					<input
						className={style.footer__emailInput}
						id='email'
						name='email'
						type='email'
						placeholder='Email'
						required={true}
						pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
					/>

					<div className={style.footer__buttons}>
						<Button
							variant='lightGreen'
							size={isTablet ? 'large' : 'small'}
							text='Отправить'
							type='submit'
						/>
					</div>
				</form>
				<div className={style.footer__circle} />
			</ContainerUI>

			<div className={style.footer__contacts}>
				<ContainerUI>
					<h3 className={style.footer__title}>Контакты</h3>

					<div className={style.footer__emailWrapper}>
						<span className={style.footer__labelEmail}>Email</span>
						<span className={style.footer__email}>info@opengarage.pro</span>
					</div>
				</ContainerUI>
			</div>

			<LogoWrapper />
		</footer>
	)
}
