import { FC } from 'react'

import classNames from 'classnames'

import { ReactComponent as StarIcon } from 'src/assets/star.svg'

import style from './style.module.scss'

type Props = {
	className?: string
	rating: number
}

export const Stars: FC<Props> = ({ rating, className }) => {
	const stars = Array(5).fill(0)

	return (
		<div
			className={classNames(style.star, {
				[`${className}`]: className,
			})}
		>
			{stars.map((_, index) => (
				<StarIcon
					key={index}
					className={classNames(style.star__icon, {
						[style['star__icon--fill']]: index < Math.floor(rating),
					})}
				/>
			))}
		</div>
	)
}
