import { CSSProperties, FC } from 'react'

import classnames from 'classnames'

import { formatNumber } from 'src/utils/formatNumber'
import { ReactComponent as StarIcon } from 'src/assets/star.svg'

import styles from './style.module.scss'

type Props = {
	rating: number
	className?: string
	style?: CSSProperties
}

export const Rating: FC<Props> = ({ rating, className, style }) => (
	<div
		className={classnames(styles.rating, {
			[`${className}`]: className,
		})}
		style={style}
	>
		<span>{formatNumber(rating)}</span>
		<StarIcon className={styles.rating__icon} />
	</div>
)
