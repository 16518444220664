import { CSSProperties, FC, useState } from 'react'

import Slider from 'react-slider'
import classnames from 'classnames'

import { Rating } from 'src/components/UiKit/Rating'

import style from './style.module.scss'

type Props = {
	label: string
	className?: string
	value: number
	onChange: (value: number) => void
}

type ThumbProps = {
	// eslint-disable-next-line
	props: any
	state: {
		index: number
		value: number
		valueNow: number
	}
}

type TrackProps = {
	// eslint-disable-next-line
	props: any
	state: {
		index: number
		value: number
	}
}

/* Функция которая удаляет из spread-а key. */
// eslint-disable-next-line
const removeKeyFromProps = (props: any) => {
	const { key, ...rest } = props
	void key
	return rest
}

/* Ползунок слайдера */
const Thumb: FC<ThumbProps> = ({ props, state }) => {
	/* Стили для отображения рейтинга над ползунком */
	const styles: CSSProperties = {
		left: `${(state.valueNow / 5) * 100}%`,
		transform: state.valueNow === 5 ? 'translateX(-100%)' : undefined, //  Иначе рейтинг будет вылетать за пределы ползунка
		opacity: state.valueNow !== 0 ? 1 : 0,
	}

	return (
		<div>
			<Rating
				className={style.range__rating}
				rating={state.valueNow}
				style={styles}
			/>
			<div {...removeKeyFromProps(props)} />
		</div>
	)
}

/* Трек слайдера */
const Track: FC<TrackProps> = ({ props, state }) => {
	const minValue = 0 // Минимальное значение
	const maxValue = 5 // Максимальное значение

	// Вычисляем процентное значение
	const percentageValue =
		((state.value - minValue) / (maxValue - minValue)) * 100

	/* Стили трека */
	const styles: CSSProperties = {
		width: `${percentageValue}%`,
		backgroundColor: '#14362f',
		transition: 'width 0.3s ease',
		borderRadius: '100px',
	}

	return <div {...removeKeyFromProps(props)} style={styles} />
}

export const RangeRating: FC<Props> = ({
	label,
	className,
	onChange,
	value,
	...args
}) => {
	const [state, setState] = useState(value)

	const handleSliderChange = (value: number) => {
		setState(value)
		onChange(value)
	}

	return (
		<div
			className={classnames(style.range__wrapper, {
				[`${className}`]: className,
			})}
		>
			<span className={style.range__label}>{label}:</span>

			<div className={style.range__sliderWrapper}>
				<div className={style.range__values}>
					<span>0</span>
					<span>5</span>
				</div>

				<Slider
					className={style.range__slider}
					max={5}
					min={0}
					minDistance={1}
					onChange={handleSliderChange}
					thumbClassName={style.range__thumb}
					trackClassName={style.range__track}
					value={state}
					renderThumb={(props, state) => (
						<Thumb key={state.index} props={props} state={state} />
					)}
					renderTrack={(props, state) => (
						<Track key={state.index} props={props} state={state} />
					)}
					{...args}
				/>
			</div>
		</div>
	)
}
