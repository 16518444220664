import { TCard, TFilterCars } from 'src/types'

export const filterCars = (filter: TFilterCars, cars: TCard[]) =>
	cars.filter((car) => {
		/* Фильтрация по названию */
		if (
			filter.search.length > 0 &&
			!car.title.toLowerCase().includes(filter.search.toLowerCase())
		)
			return false

		/* Фильтрации по параметрам */
		if (filter.brand && car.brand.toLowerCase() !== filter.brand.toLowerCase())
			return false
		if (filter.model && car.model.toLowerCase() !== filter.model.toLowerCase())
			return false
		if (filter.body && car.body.toLowerCase() !== filter.body.toLowerCase())
			return false
		if (filter.type && car.type.toLowerCase() !== filter.type.toLowerCase())
			return false

		/* Фильтрация по диапазону рейтинга */
		if (
			car.rating < filter.ratingRange[0] ||
			car.rating > filter.ratingRange[1]
		)
			return false

		return true
	})
