import { FC } from 'react'

import classNames from 'classnames'

import { formatNumber } from 'src/utils/formatNumber'

import style from './style.module.scss'

type Props = {
	rating: number
	className?: string
}

export const TagRating: FC<Props> = ({ rating, className }) => (
	<div
		className={classNames(style.tag__rating, {
			[style['tag__rating--bad']]: rating <= 3,
			[style['tag__rating--good']]: rating > 3,
			[`${className}`]: className,
		})}
	>
		{formatNumber(rating)}
	</div>
)
