import { FC } from 'react'

import { ReactComponent as CloseIcon } from 'src/assets/close.svg'

import style from './style.module.scss'

type Props = {
	title: string
	id: string
	onDelete: (id: string) => void
}

export const Tag: FC<Props> = ({ onDelete, title, id }) => (
	<button
		className={style.tag}
		id={id}
		type='button'
		onClick={() => onDelete(id)}
	>
		<CloseIcon className={style.tag__icon} />
		<span className={style.tag__title}>{title}</span>
	</button>
)
