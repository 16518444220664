import { FC } from 'react'

import classNames from 'classnames'

import { ReactComponent as CloseIcon } from 'src/assets/close.svg'

import style from './style.module.scss'

type Props = {
	variant: 'green' | 'white'
	onClick?: () => void
	className?: string
}

export const ButtonClose: FC<Props> = ({ variant, className, onClick }) => (
	<button
		className={classNames(style.close, style[`close--${variant}`], {
			[`${className}`]: className,
		})}
		onClick={onClick}
	>
		<CloseIcon className={style.close__icon} />
	</button>
)
