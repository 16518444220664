import { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import { Banner } from 'src/components/Banner'
import { Models } from 'src/components/CarsModels'
import { Faq } from 'src/components/Faq'
import { Footer } from 'src/components/Footer'
import { Header } from 'src/components/Header'
import { Hero } from 'src/components/Hero'
import { ModalComment } from 'src/components/ModalComment'
import ModalConfirmation from 'src/components/ModalConfirmation'
import { ContainerUI } from 'src/components/UiKit/Container'
import { MainLayout } from 'src/components/UiKit/MainLayout'
import mockCards from 'src/mocks/cards.json'
import { TCard, TFilterCars } from 'src/types'
import { extractKeyValues } from 'src/utils/extractKeyValues'
import { filterCars } from 'src/utils/filterCars'

import style from 'src/styles/page.module.scss'
import calculateAverage from 'src/utils/calculateAverage'

export const Home: FC = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false)
	const [modelCards, setModelCards] = useState<TCard[]>([])
	const [filterCarsState, setFilterCarsState] = useState<TCard[]>([])
	const [filterModalCarsState, setFilterModalCarsState] = useState<TCard[]>([])

	/* Инициализируем параметры фильтров */
	const models = extractKeyValues(filterCarsState, 'title') as string[]
	const brands = extractKeyValues(filterCarsState, 'brand') as string[]
	const bodys = extractKeyValues(filterCarsState, 'body') as string[]
	const types = extractKeyValues(filterCarsState, 'type') as string[]

	const modalBrands = extractKeyValues(
		filterModalCarsState,
		'brand'
	) as string[]
	const modalModels = extractKeyValues(
		filterModalCarsState,
		'model'
	) as string[]
	const modalBodys = extractKeyValues(filterModalCarsState, 'body') as string[]

	const handleOpenModal = () => setIsModalOpen(true)
	const handleOpenModalConfirm = () => setIsModalConfirmOpen(true)

	const handleFiltersChange = (filters: TFilterCars) => {
		const filterData = filterCars(filters, mockCards as TCard[])
		setFilterCarsState(filterData)
	}

	const handleFilterCards = (filters: TFilterCars) => {
		const filterData = filterCars(filters, mockCards as TCard[])
		setModelCards(filterData)
	}

	const handleFiltersModalCarChagne = ({
		carBrand,
		carModel,
		carBody,
	}: {
		carBrand: string
		carModel: string
		carBody: string
	}) => {
		const filterData = filterCars(
			{
				brand: carBrand,
				model: carModel,
				body: carBody,
				search: '',
				ratingRange: [0, 5],
			} as TFilterCars,
			mockCards as TCard[]
		)
		setFilterModalCarsState(filterData)
	}

	// вычисление рейтинга машины на основе комментариев
	useEffect(() => {
		const cars = mockCards.map((car) => {
			car.rating = calculateAverage(
				extractKeyValues(car.comments, 'stars') as number[]
			)

			return car
		})

		setFilterCarsState(cars as TCard[])
		setModelCards(cars as TCard[])
		setFilterModalCarsState(cars as TCard[])
	}, [])

	return (
		<MainLayout>
			<Header />

			<ModalComment
				bodys={modalBodys}
				brands={modalBrands}
				models={modalModels}
				isOpen={isModalOpen}
				handleOpenConfirmModal={handleOpenModalConfirm}
				handleCloseModal={() => setIsModalOpen(false)}
				handleFilterCarChange={handleFiltersModalCarChagne}
			/>

			<ModalConfirmation
				isOpen={isModalConfirmOpen}
				handleCloseModal={() => setIsModalConfirmOpen(false)}
			/>

			<main className={style.main}>
				<section className={style.main__section}>
					<ContainerUI>
						<Hero handleOpenModal={handleOpenModal} />
					</ContainerUI>
				</section>

				<section className={style.main__section} id='car-models'>
					<ContainerUI>
						<Models
							bodys={bodys}
							brands={brands}
							models={models}
							types={types}
							cards={modelCards as TCard[]}
							handleFiltersChange={handleFiltersChange}
							handleFilterCars={handleFilterCards}
						/>
					</ContainerUI>
				</section>

				<section
					className={classNames(
						style.main__section,
						style['main__section--no-center']
					)}
				>
					<Banner handleOpenModal={handleOpenModal} />
				</section>

				<section className={style.main__section} id='faq'>
					<ContainerUI>
						<Faq />
					</ContainerUI>
				</section>
			</main>

			<Footer />
		</MainLayout>
	)
}
