import { FC } from 'react'

import { ReactComponent as ArrowIcon } from 'src/assets/arrow.svg'

import style from './style.module.scss'

export const Arrow: FC = () => (
	<div className={style.arrow}>
		<ArrowIcon className={style.arrow__icon} />
	</div>
)
