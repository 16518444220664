import { FC } from 'react'

import { useMediaQuery } from 'react-responsive'

import { Button } from 'src/components/UiKit/Button'
import { ContainerUI } from 'src/components/UiKit/Container'
import userCommentLogo from 'src/assets/userComment.png'

import style from './style.module.scss'

type Props = {
	handleOpenModal: () => void
}

export const Banner: FC<Props> = ({ handleOpenModal }) => {
	const isTablet = useMediaQuery({ maxWidth: 768 })

	return (
		<div className={style.bannerWrapper}>
			<ContainerUI className={style.banner}>
				<div className={style.banner__titles}>
					<h2 className={style.banner__title}>
						Оставьте отзыв об автомобилях, на которых вы ездили.
					</h2>

					<p className={style.banner__text}>
						Сотни пользователей уже оставили свой отзыв
					</p>

					<div className={style.banner__buttons}>
						<Button
							text='Оставить отзыв'
							variant='green'
							size={isTablet ? 'large' : 'small'}
							onClick={handleOpenModal}
						/>
					</div>
				</div>

				<div className={style.banner__images}>
					<img
						className={style['banner__image--background']}
						src={userCommentLogo}
						alt='Отзыв пользователя'
					/>

					<img
						className={style['banner__image--foreground']}
						src={userCommentLogo}
						alt='Отзыв пользователя'
					/>

					<div className={style.banner__circle} />
				</div>
			</ContainerUI>
		</div>
	)
}
