import { FC, useLayoutEffect, useRef, useState } from 'react'
import mockCards from 'src/mocks/cards.json'

import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import { Filters } from 'src/components/Filters'
import { Button } from 'src/components/UiKit/Button'
import { Card } from 'src/components/UiKit/Card'
import { SliderCarsCards } from 'src/components/UiKit/SliderCarsCards'
import { TCard, TFilterCars } from 'src/types'

import style from './style.module.scss'

type Props = {
	brands: string[]
	models: string[]
	bodys: string[]
	types: string[]
	cards: TCard[]
	handleFilterCars: (filters: TFilterCars) => void
	handleFiltersChange: (filters: TFilterCars) => void
}

/* Карточки в Desktop версии */
const DesktopCards: FC<{
	cards: TCard[]
	noFullWidth: boolean
	cardsColumn: boolean
}> = ({ cards, noFullWidth, cardsColumn }) => (
	<div
		className={classNames(style.models__cards, {
			[style['models__cards--overflow-mobile']]: cardsColumn,
		})}
		id='slider-cars'
	>
		{cards.map((card) => (
			<Card
				className={classNames({
					[style['models__card--column']]: cardsColumn,
				})}
				noFullWidth={noFullWidth}
				data={card}
				key={card.id}
			/>
		))}
	</div>
)

export const Models: FC<Props> = ({
	cards,
	handleFilterCars,
	handleFiltersChange,
	bodys,
	brands,
	models,
	types,
}) => {
	const isDesktop = useMediaQuery({ minWidth: 1220 })
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1219 })
	const isMobile = useMediaQuery({ maxWidth: 600 })
	const [offset, setOffset] = useState(4)
	const isMaxCardViewOver = offset >= cards.length
	const [isMoreCardView, setIsMoreCardView] = useState(false)

	const scrollPositionRef = useRef(0)

	useLayoutEffect(() => {
		window.scrollTo(0, scrollPositionRef.current)
	}, [offset])

	const handleShowMore = () => {
		document.documentElement.style.scrollBehavior = 'unset'
		scrollPositionRef.current = window.scrollY
		setOffset((prevState) => prevState + 4)
		setIsMoreCardView(true)
		setTimeout(() => {
			document.documentElement.style.scrollBehavior = 'smooth'
		}, 1000)
	}

	return (
		<div className={style.models}>
			<div className={style.models__header}>
				<h2 className={style.models__title}>Модели</h2>

				<p className={style.models__text}>
					Оцените модели автомобилей, которые вы знаете. <br /> Ознакомьтесь с
					подробными характеристиками и отзывами реальных владельцев о
					популярных моделях. Помогите будущим покупателям сделать правильный
					выбор, оставив свой отзыв и поставив оценку на основе личного опыта.
					Ваше мнение поможет сформировать рейтинг каждого автомобиля!
				</p>
			</div>

			<Filters
				bodys={bodys}
				brands={brands}
				models={models}
				types={types}
				handleFilterCars={handleFilterCars}
				handleFiltersChange={handleFiltersChange}
			/>

			{isDesktop || isMoreCardView ? (
				<DesktopCards
					noFullWidth={!isMobile}
					cardsColumn={isMoreCardView && !isDesktop}
					cards={cards.slice(0, offset)}
				/>
			) : (
				<div className={style.models__sliderWrapper} id='slider-cars'>
					<SliderCarsCards
						slidesToShow={isTablet ? 3 : 1}
						slidesToScroll={1}
						cards={
							cards.length === 0
								? (mockCards.slice(0, offset) as TCard[])
								: cards.slice(0, offset)
						}
					/>
				</div>
			)}

			<div className={style.models__buttons}>
				{!isMaxCardViewOver && (
					<Button
						text='Показать больше'
						variant='green'
						size='large'
						onClick={handleShowMore}
					/>
				)}
			</div>
		</div>
	)
}
