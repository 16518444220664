import { FC } from 'react'

import classNames from 'classnames'
import { FieldError } from 'react-hook-form'

import style from './style.module.scss'

type Props = {
	className?: string
	error: FieldError | undefined
}

export const ErrorMessage: FC<Props> = ({ error, className }) => (
	<span
		className={classNames(style.errorMessage, {
			[`${className}`]: className,
		})}
	>
		{error?.message}
	</span>
)
