import { FC } from 'react'

import { TComment } from 'src/types'
import { Stars } from 'src/components/UiKit/Stars'
import { RatingBand } from 'src/components/UiKit/RatingBand'

import style from './style.module.scss'

type Props = {
	data: TComment
}

export const Comment: FC<Props> = ({
	data: { experience, mileage, author, stars, text, date, carScores },
}) => (
	<div className={style.comment}>
		<span className={style.comment__date}>{date}</span>

		<div className={style.comment__info}>
			<span className={style.comment__name}>{author}</span>

			<div className={style.comment__data}>
				<Stars rating={stars} />
				<span>Опыт владения: {experience} года</span>
				<span>Пробег: {mileage.toLocaleString('ru-RU')}</span>
			</div>

			<p className={style.comment__text}>{text}</p>
		</div>

		<div className={style.comment__properties}>
			<div className={style.comment__column}>
				<RatingBand property='Надежность' rating={carScores.reliability} />
				<RatingBand property='Управляемость' rating={carScores.manageability} />
				<RatingBand property='Комфорт' rating={carScores.comfort} />
			</div>

			<div className={style.comment__column}>
				<RatingBand property='Оборудованность' rating={carScores.equipment} />
				<RatingBand property='Премиальность' rating={carScores.premium} />
				<RatingBand
					property='Экономичность'
					rating={carScores.costEffectiveness}
				/>
			</div>
		</div>
	</div>
)
