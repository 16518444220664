import { FC, MouseEvent } from 'react'

import SlickSlider from 'react-slick'

import { CustomArrow } from 'src/components/UiKit/CustomArrow'

import styles from './style.module.scss'

type Props = {
	slides: { carBrand: string; cdn: string }[]
}

export const Slider: FC<Props> = ({ slides }) => {
	const settings = {
		dots: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		swipeToSlide: true,
		speed: 500,
		touchThreshold: 30,
		prevArrow: <CustomArrow direction='prev' />,
		nextArrow: <CustomArrow direction='next' />,
		responsive: [
			{
				breakpoint: 960,
				settings: {
					arrows: false,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
					arrows: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					arrows: false,
				},
			},
		],
	}

	const onClick = (e: MouseEvent, carBrand: string) => {
		e.preventDefault()
		const buttonOpenFilter = document.getElementById('filters-open-button')

		if (buttonOpenFilter?.getAttribute('data-status') === 'close') {
			buttonOpenFilter.click()
		}

		const buttonBrand = document.getElementById('brand')
		buttonBrand?.click()

		if (buttonBrand?.getAttribute('data-status') === 'active') {
			const list = document.getElementById('dropdown-list')
			const node = list?.querySelector('.dropdown__node') as HTMLButtonElement
			node.click()

			setTimeout(() => {
				document.getElementById(carBrand)?.click()
			}, 100)
		} else {
			document.getElementById(carBrand)?.click()
		}

		setTimeout(() => {
			document.getElementById('modelsFilters-submit-button')?.click()
			document.getElementById('slider-cars')?.scrollIntoView()
		}, 100)
	}

	return (
		<div className={styles.slider}>
			<SlickSlider {...settings}>
				{slides.map((slide, index) => (
					<div key={index} className={styles.slider__slide}>
						<a
							href='#slider-cars'
							onClick={(e) => onClick(e, slide.carBrand)}
							className={styles.slider__logo}
						>
							<img
								className={styles.slider__image}
								src={slide.cdn}
								alt='Логотип автомобиля'
							/>
						</a>
					</div>
				))}
			</SlickSlider>
		</div>
	)
}
