import { FC, useRef, useState } from 'react'

import classNames from 'classnames'

import { FilterStars } from 'src/components/FilterStars'
import { FilterProperties } from 'src/components/FilterProperties'
import { ReactComponent as PlusIcon } from 'src/assets/lightPlus.svg'
import { ReactComponent as MinusIcon } from 'src/assets/lightLine.svg'
import { TCarScores, TComment } from 'src/types'

import style from './style.module.scss'

type Props = {
	comments: TComment[]
	updateStarsFilters: (t: number) => void
	defaultPropertiesFilter: TCarScores
	updatePropertiesFilters: (t: Partial<TCarScores>) => void
}

export const ReviewsFilters: FC<Props> = ({
	comments,
	updateStarsFilters,
	updatePropertiesFilters,
	defaultPropertiesFilter,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const contentRef = useRef<HTMLDivElement | null>(null)

	/* Стили для анимации развертывания */
	const animatedStyles = isOpen
		? { maxHeight: contentRef.current?.scrollHeight }
		: { maxHeight: 0 }

	return (
		<div
			className={classNames(style.reviewsFilters, {
				[style['reviewsFilters--open']]: isOpen,
			})}
		>
			<div className={style.reviewsFilters__head}>
				<span className={style.reviewsFilters__title}>Фильтр</span>

				<button
					className={style.reviewsFilters__button}
					onClick={() => setIsOpen((prev) => !prev)}
				>
					<span>{isOpen ? 'Свернуть' : 'Развернуть'}</span>
					{isOpen ? (
						<MinusIcon className={style.reviewsFilters__icon} />
					) : (
						<PlusIcon className={style.reviewsFilters__icon} />
					)}
				</button>
			</div>

			<div
				className={style.reviewsFilters__contentWrapper}
				style={animatedStyles}
				ref={contentRef}
			>
				<div className={style.reviewsFilters__content}>
					<div
						className={classNames(
							style.reviewsFilters__column,
							style.column__stars
						)}
					>
						<p className={style.reviewsFilters__subtitle}>
							Выберите строку ниже, чтобы отфильтровать отзывы
						</p>

						<FilterStars comments={comments} onChange={updateStarsFilters} />
					</div>

					<div
						className={classNames(
							style.reviewsFilters__column,
							style.column__properties
						)}
					>
						<p className={style.reviewsFilters__subtitle}>
							Выберите строку ниже, чтобы отфильтровать отзывы
						</p>

						<FilterProperties
							updatePropertiesFilters={updatePropertiesFilters}
							defaultPropertiesFilters={defaultPropertiesFilter}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
