import { forwardRef, useEffect, useRef, useState } from 'react'

import classnames from 'classnames'
import { FieldError } from 'react-hook-form'

import { ErrorMessage } from 'src/components/UiKit/ErrorMessage'
import { ReactComponent as DownArrowIcon } from 'src/assets/downArrow.svg'

import style from './style.module.scss'

type Props = {
	title: string
	options: string[]
	error: FieldError | undefined
	onChange: (value: string | undefined, id: string) => void
	id: string
	value: string | undefined
}

export const Dropdown = forwardRef<HTMLButtonElement, Props>(
	({ error, options, title, onChange, id, value }, ref) => {
		const [isOpen, setIsOpen] = useState<boolean>(false)
		const [selectedOption, setSelectedOption] = useState<string | undefined>(
			value
		)
		const contentRef = useRef<HTMLUListElement | null>(null)
		const dropdownRef = useRef<HTMLDivElement | null>(null)

		/* Стили для анимации развертывания */
		const animatedStyles = isOpen
			? { maxHeight: '220px' }
			: { maxHeight: 0, borderBottom: 'none' }

		const toggleDropdown = () => {
			setIsOpen(!isOpen)
		}

		const handleOptionClick = (option: string, id: string) => {
			if (selectedOption && selectedOption === option) {
				setIsOpen(false)
				setSelectedOption(undefined)
				onChange(undefined, id)
			} else {
				setIsOpen(false)
				setSelectedOption(option)
				onChange(option, id)
			}
		}

		// Обработчик клика вне меню
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false)
			}
		}

		useEffect(() => {
			document.addEventListener('mousedown', handleClickOutside)

			return () => {
				document.removeEventListener('mousedown', handleClickOutside)
			}
		}, [])

		/* Если значение этого поля сбросили то убираем выбранный пункт */
		useEffect(() => {
			if (value === undefined) setSelectedOption(undefined)
		}, [value])

		return (
			<div className={style.dropdown} ref={dropdownRef}>
				<div className={style.dropdown__wrapper}>
					<button
						className={classnames(style.dropdown__button, {
							[style['dropdown__button--open']]: isOpen,
						})}
						type='button'
						onClick={toggleDropdown}
						id={id}
						data-status={selectedOption ? 'active' : 'none'}
						ref={ref}
					>
						<span
							className={classnames(style.dropdown__title, {
								[style['dropdown__title--uppercase']]: selectedOption,
							})}
						>
							{selectedOption || title}
						</span>
						<DownArrowIcon
							className={classnames(style.dropdown__icon, {
								[style['dropdown__icon--open']]: isOpen,
							})}
						/>
					</button>

					<ul
						className={style.dropdown__content}
						ref={contentRef}
						style={animatedStyles}
						id='dropdown-list'
					>
						{options.map((option, index) => (
							<li
								key={index}
								className={classnames(
									style.dropdown__option,
									'dropdown__node',
									{
										[style['dropdown__option--active']]:
											selectedOption === option,
									}
								)}
								onClick={() => handleOptionClick(option, id)}
								id={option}
								data-index={index}
							>
								{option}
							</li>
						))}
					</ul>
				</div>

				<ErrorMessage error={error} />
			</div>
		)
	}
)

Dropdown.displayName = 'Dropdown'
