import { FC, useEffect } from 'react'
import { ReactComponent as CheckIcon } from 'src/assets/check.svg'
import { ButtonClose } from 'src/components/UiKit/ButtonClose'

import style from './style.module.scss'

type Props = {
	handleCloseModal: () => void
	isOpen: boolean
}

const ModalConfirmation: FC<Props> = ({ handleCloseModal, isOpen }) => {
	useEffect(() => {
		if (isOpen) {
			document.body.classList.add('modalOpen')
		}
		return () => {
			document.body.classList.remove('modalOpen')
		}
	}, [])

	if (!isOpen) return null

	return (
		<div className={style.modalConfirmation__wrapper}>
			<div className={style.modalConfirmation__content}>
				<ButtonClose
					className={style.modalConfirmation__buttonClose}
					onClick={handleCloseModal}
					variant='white'
				/>

				<div className={style.modalConfirmation__iconWrapper}>
					<CheckIcon />
				</div>

				<h1 className={style.modalConfirmation__title}>Отзыв отправлен</h1>
			</div>
		</div>
	)
}
export default ModalConfirmation
