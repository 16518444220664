import { FC, MouseEvent } from 'react'

import classNames from 'classnames'

import style from './style.module.scss'

type Props = {
	text: string
	variant: 'black' | 'green' | 'lightGreen'
	size: 'large' | 'small'
	className?: string
	onClick?: (e: MouseEvent) => void
	type?: 'button' | 'submit'
	id?: string
}

export const Button: FC<Props> = ({
	text,
	variant,
	size,
	className,
	onClick,
	type,
	id,
}) => (
	<button
		className={classNames(
			style.button,
			style[`button--${variant}`],
			style[`button--${size}`],
			{
				[`${className}`]: className,
			}
		)}
		onClick={onClick}
		type={type}
		id={id}
	>
		{text}
	</button>
)
