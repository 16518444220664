import classNames from 'classnames'
import { FC } from 'react'

import style from './style.module.scss'

type Props = {
	to: string
	text: string
	variant: 'black' | 'green' | 'lightGreen'
	size: 'large' | 'small'
}

export const LinkUI: FC<Props> = ({ size, text, to, variant }) => (
	<a
		className={classNames(
			style.link,
			style[`link--${variant}`],
			style[`link--${size}`]
		)}
		href={to}
	>
		{text}
	</a>
)
