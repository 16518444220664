import classNames from 'classnames'
import { FC } from 'react'

import style from './style.module.scss'
import { formatNumber } from 'src/utils/formatNumber'

type Props = {
	rating: number
	property: string
	className?: string
}

export const RatingBand: FC<Props> = ({ rating, className, property }) => {
	const band = Array(5).fill(0)

	return (
		<div
			className={classNames(style.ratingBand, {
				[`${className}`]: className,
			})}
		>
			<span className={style.ratingBand__property}>{property}</span>

			<div className={style.ratingBand__list}>
				{band.map((_, index) => (
					<div
						key={index}
						className={classNames(style.ratingBand__icon, {
							[style['ratingBand__icon--on']]: index < Math.floor(rating),
						})}
					/>
				))}
			</div>

			<span className={style.ratingBand__rating}>{formatNumber(rating)}</span>
		</div>
	)
}
