import { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import { ReactComponent as StarIcon } from 'src/assets/star.svg'

import style from './style.module.scss'
import { TComment } from 'src/types'

type StarProgressProps = {
	number: number
	fullness: number
	onClick: (t: number) => void
	isActive: boolean
}

const StarProgress: FC<StarProgressProps> = ({
	number,
	fullness,
	onClick,
	isActive,
}) => (
	<button className={style.starProgress} onClick={() => onClick(number)}>
		<div className={style.starProgress__rating}>
			<span className={style.starProgress__ratingNumber}>{number}</span>
			<StarIcon className={style.starProgress__icon} />
		</div>

		<div className={style.starProgress__progressWrapper}>
			<div
				className={classNames(style.starProgress__track, {
					[style['starProgress__track--full-border-radius']]: fullness === 1,
					[style['starProgress__track--active']]: isActive,
				})}
				style={{ width: `${fullness * 100}%` }}
			/>
		</div>
	</button>
)

type Props = {
	comments: TComment[]
	onChange: (t: number) => void
}

export const FilterStars: FC<Props> = ({ comments, onChange }) => {
	const [activeFilter, setActiveFilter] = useState(0)
	const stars = Array(5).fill(0)
	const totalReviews = comments.length
	let five = 0
	let four = 0
	let three = 0
	let two = 0
	let one = 0

	const onClick = (t: number) => {
		if (t === activeFilter) {
			setActiveFilter(0)
			onChange(0)
		} else {
			setActiveFilter(t)
			onChange(t)
		}
	}

	useEffect(() => {
		setActiveFilter(0)
	}, [comments])

	comments.map((item) => {
		const { stars } = item

		if (stars === 5) five++
		if (stars === 4) four++
		if (stars === 3) three++
		if (stars === 2) two++
		if (stars === 1) one++
	})

	/* Рассчитываем пропорции отзывов на каждую звезду */
	const fiveStarRatio = totalReviews > 0 ? five / totalReviews : 0
	const fourStarRatio = totalReviews > 0 ? four / totalReviews : 0
	const oneStarRatio = totalReviews > 0 ? one / totalReviews : 0
	const threeStarRatio = totalReviews > 0 ? three / totalReviews : 0
	const twoStarRatio = totalReviews > 0 ? two / totalReviews : 0

	const fullness = [
		oneStarRatio,
		twoStarRatio,
		threeStarRatio,
		fourStarRatio,
		fiveStarRatio,
	]

	return (
		<div className={style.filtersStar}>
			<ul className={style.filterStar__list}>
				{stars.map((_, index) => (
					<li key={index}>
						<StarProgress
							fullness={fullness[index]}
							number={index + 1}
							onClick={onClick}
							isActive={index + 1 === activeFilter}
						/>
					</li>
				))}
			</ul>
		</div>
	)
}
