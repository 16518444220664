import { FC, useRef, useState } from 'react'

import { TAccordion } from 'src/types'
import { ReactComponent as PlusIcon } from 'src/assets/boldPlus.svg'
import { ReactComponent as MinusIcon } from 'src/assets/boldLine.svg'

import style from './style.module.scss'

type Props = {
	data: TAccordion
}

export const Accordion: FC<Props> = ({ data: { title, answer } }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const contentRef = useRef<HTMLDivElement | null>(null)

	/* Стили для анимации развертывания */
	const animatedStyles = isOpen
		? { maxHeight: contentRef.current?.scrollHeight }
		: { maxHeight: 0, border: 0 }

	return (
		<div className={style.accordion}>
			<div className={style.accordion__head}>
				<span className={style.accordion__title}>{title}</span>
				<button
					className={style.accordion__button}
					onClick={() => setIsOpen((prev) => !prev)}
				>
					{isOpen ? <MinusIcon /> : <PlusIcon />}
				</button>
			</div>

			<div
				className={style.accordion__content}
				ref={contentRef}
				style={animatedStyles}
			>
				<p className={style.accordion__answer}>{answer}</p>
			</div>
		</div>
	)
}
