import { forwardRef, HTMLAttributes } from 'react'

import { FieldError } from 'react-hook-form'

import { ErrorMessage } from 'src/components/UiKit/ErrorMessage'

import style from './style.module.scss'

type Props = HTMLAttributes<HTMLTextAreaElement> & {
	placeholder?: string
	error: FieldError | undefined
}

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
	({ placeholder, error, ...args }, ref) => (
		<div>
			<textarea
				className={style.textarea}
				placeholder={placeholder}
				ref={ref}
				{...args}
			/>
			<ErrorMessage error={error} />
		</div>
	)
)

Textarea.displayName = 'Textarea'
