import { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import { Stars } from 'src/components/UiKit/Stars'
import { Button } from 'src/components/UiKit/Button'
import { ContainerUI } from 'src/components/UiKit/Container'
import { TagRating } from 'src/components/UiKit/TagRating'
import { ButtonClose } from 'src/components/UiKit/ButtonClose'

import style from './style.module.scss'

type Props = {
	rating: number
	carName: string
	wallpaper: string
	handleOpenModal: () => void
}

export const HeroCar: FC<Props> = ({
	wallpaper,
	rating,
	carName,
	handleOpenModal,
}) => {
	const navigate = useNavigate()

	return (
		<div
			className={style.heroCar}
			style={{
				backgroundImage: `url(${wallpaper})`,
			}}
		>
			<ContainerUI className={style.heroCar__content}>
				<div className={style.heroCar__header}>
					<TagRating className={style.heroCar__rating} rating={rating} />
					<ButtonClose
						className={style.heroCar__buttonClose}
						variant='green'
						onClick={() => {
							navigate('/')

							setTimeout(() => {
								window.location.hash = '#car-models'
							}, 100)
						}}
					/>
				</div>

				<div className={style.heroCar__titles}>
					<h1 className={style.heroCar__title}>{carName}</h1>
					<Stars rating={rating} />
				</div>

				<div className={style.heroCar__commentWrapper}>
					<Button
						size='large'
						text='Оставить отзыв'
						variant='green'
						onClick={handleOpenModal}
					/>
				</div>
			</ContainerUI>
		</div>
	)
}
