import { FC, HTMLAttributes } from 'react'

import classNames from 'classnames'

import style from './style.module.scss'

type Props = HTMLAttributes<HTMLHRElement> & {
	className?: string
}

export const Horizontal: FC<Props> = ({ className, ...args }) => (
	<hr
		className={classNames(style.horizontal, {
			className: className !== undefined,
		})}
		{...args}
	/>
)
