import { forwardRef, HTMLAttributes } from 'react'

import { FieldError } from 'react-hook-form'

import { ErrorMessage } from 'src/components/UiKit/ErrorMessage'

import style from './style.module.scss'

type Props = HTMLAttributes<HTMLInputElement> & {
	labelText: string
	error: FieldError | undefined
	id: string
}

export const InputNumber = forwardRef<HTMLInputElement, Props>(
	({ labelText, error, id, ...args }, ref) => (
		<div className={style.inputNumber}>
			<input
				className={style.inputNumber__input}
				type='text'
				inputMode='decimal'
				placeholder=' '
				id={id}
				ref={ref}
				{...args}
			/>

			<label className={style.inputNumber__label} htmlFor={id}>
				{labelText}
			</label>

			<ErrorMessage className={style.inputNumber__error} error={error} />
		</div>
	)
)

InputNumber.displayName = 'InputNumber'
