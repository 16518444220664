import { FC } from 'react'

import { ReactComponent as DriveIcon } from 'src/assets/drive.svg'
import { ReactComponent as EngineIcon } from 'src/assets/engine.svg'
import { ReactComponent as DrivingIcon } from 'src/assets/driving.svg'
import { ReactComponent as TransmissionIcon } from 'src/assets/transmission.svg'

import style from './style.module.scss'
import { TCarCharacteristicVariant } from 'src/types'

type Props = {
	variant: TCarCharacteristicVariant
	text: string
}

const icons = {
	engine: <EngineIcon />,
	drive: <DriveIcon />,
	driving: <DrivingIcon />,
	transmission: <TransmissionIcon />,
}

export const CarSpecs: FC<Props> = ({ variant, text }) => (
	<div className={style.carSpecs}>
		<div className={style.carSpecs__iconWrapper}>{icons[variant]}</div>
		<div className={style.carSpecs__textWrapper}>
			<span>{text}</span>
		</div>
	</div>
)
