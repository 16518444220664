import { FC } from 'react'

import classNames from 'classnames'

import style from './style.module.scss'
import Preloader from '../Preloader'

type Props = {
	text: string
	variant: 'black' | 'green' | 'lightGreen'
	size: 'large' | 'small'
	className?: string
	onClick?: () => void
	type?: 'button' | 'submit'
	isLoading?: boolean
	error?: string
}

export const ButtonLoading: FC<Props> = ({
	text,
	variant,
	size,
	className,
	onClick,
	type,
	isLoading,
	error,
}) => (
	<div className={style.buttonLoading__wrapper}>
		<span className={style.buttonLoading__errorText}>{error && error}</span>
		<button
			className={classNames(
				style.buttonLoading,
				style[`buttonLoading--${variant}`],
				style[`buttonLoading--${size}`],
				{
					[`${className}`]: className,
				}
			)}
			onClick={onClick}
			type={type}
			disabled={isLoading}
		>
			<span>{text}</span>
			{isLoading && <Preloader />}
		</button>
	</div>
)
