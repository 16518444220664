import { TComment, TCarScores } from 'src/types'

const filterReviews = (
	arr: TComment[],
	starsFilter: number,
	propertiesFilters: TCarScores
) => {
	return arr.filter((comment) => {
		const {
			comfort,
			premium,
			equipment,
			reliability,
			manageability,
			costEffectiveness,
		} = comment.carScores

		if (comment.stars !== starsFilter && starsFilter !== 0) return false

		if (
			premium !== propertiesFilters.premium &&
			propertiesFilters.premium !== 0
		)
			return false

		if (
			comfort !== propertiesFilters.comfort &&
			propertiesFilters.comfort !== 0
		)
			return false

		if (
			equipment !== propertiesFilters.equipment &&
			propertiesFilters.equipment !== 0
		)
			return false

		if (
			reliability !== propertiesFilters.reliability &&
			propertiesFilters.reliability !== 0
		)
			return false

		if (
			manageability !== propertiesFilters.manageability &&
			propertiesFilters.manageability !== 0
		)
			return false

		if (
			costEffectiveness !== propertiesFilters.costEffectiveness &&
			propertiesFilters.costEffectiveness !== 0
		)
			return false

		return true
	})
}

export default filterReviews
