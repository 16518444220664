import { FC, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { Link, useLocation } from 'react-router-dom'

import { CONFIG_ROUTE_PATHS } from 'src/config'
import { ContainerUI } from 'src/components/UiKit/Container'
import { HashLink } from 'src/components/UiKit/LinkHash'
import { ReactComponent as CloseIcon } from 'src/assets/close.svg'
import { ReactComponent as BurgerIcon } from 'src/assets/burger.svg'

import style from './style.module.scss'

/* Навигационное меню */
const Nav: FC<{ tracking?: boolean; onClick?: () => void }> = ({
	tracking,
	onClick,
}) => {
	const path = useLocation()

	return (
		<nav className={style.nav}>
			<ul className={style.nav__list}>
				<li>
					<Link
						className={classNames(style.nav__link, {
							[style['nav__link--on']]:
								path.pathname === CONFIG_ROUTE_PATHS.HOME && tracking,
						})}
						to={CONFIG_ROUTE_PATHS.HOME}
						onClick={onClick}
					>
						Главная
					</Link>
				</li>
				<li>
					<a
						className={classNames(style.nav__link, {
							[style['nav__link--on']]:
								path.pathname.startsWith(CONFIG_ROUTE_PATHS.MODELS) && tracking,
						})}
						href='#car-models'
						onClick={onClick}
					>
						Модели
					</a>
				</li>
				<li>
					<HashLink
						className={style.nav__link}
						to='/'
						anchor='#faq'
						onClick={onClick}
					>
						FAQ
					</HashLink>
				</li>
				<li>
					<HashLink
						className={style.nav__link}
						to='/'
						anchor='#footer'
						onClick={onClick}
					>
						Контакты
					</HashLink>
				</li>
			</ul>
		</nav>
	)
}

/* Мобильное навигационное меню */
const MobileNav: FC = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const burgerRef = useRef<HTMLDivElement | null>(null)

	const handleClickOutside = (event: MouseEvent) => {
		if (
			burgerRef.current &&
			!burgerRef.current.contains(event.target as Node)
		) {
			setIsOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div className={style.burger} ref={burgerRef}>
			<button
				className={style.burger__button}
				onClick={() => setIsOpen((prev) => !prev)}
			>
				{isOpen ? <CloseIcon /> : <BurgerIcon />}
			</button>

			{isOpen && <Nav onClick={() => setIsOpen(false)} />}
		</div>
	)
}

export const Header: FC = () => {
	const isTablet = useMediaQuery({ minWidth: 960 })

	return (
		<header className={style.header}>
			<ContainerUI className={style.header__content}>
				<Link to='/'>
					<p className={style.header__logo}>Открытый гараж</p>
				</Link>

				{isTablet ? <Nav tracking={true} /> : <MobileNav />}
			</ContainerUI>
		</header>
	)
}
