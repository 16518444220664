import { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import { TCarScores, TComment } from 'src/types'
import { Button } from 'src/components/UiKit/Button'
import { Comment } from 'src/components/UiKit/Comment'
import { ReviewsFilters } from 'src/components/ReviewsFilters'

import style from './style.module.scss'
import filterReviews from 'src/utils/filterReviews'

type Props = {
	commentsProps: TComment[]
}

const defaultPropertiesFilter: TCarScores = {
	comfort: 0,
	premium: 0,
	equipment: 0,
	reliability: 0,
	manageability: 0,
	costEffectiveness: 0,
}

export const Reviews: FC<Props> = ({ commentsProps }) => {
	const [comments, setComments] = useState(commentsProps)
	const [offset, setOffset] = useState(3)
	const isOffsetOverLength = offset >= comments.length
	const isNotEmpty = comments.length > 0
	const [starFilter, setStarFilter] = useState<number>(0)
	const [propertiesFilters, setPropertiesFilters] = useState<TCarScores>(
		defaultPropertiesFilter
	)

	const updateStarFilter = (star: number) => {
		setStarFilter(star)
	}

	const updatePropertiesFilter = (property: Partial<TCarScores>) => {
		setPropertiesFilters((prev) => ({
			...prev,
			...property,
		}))
	}

	useEffect(() => {
		setComments(filterReviews(commentsProps, starFilter, propertiesFilters))
	}, [propertiesFilters, starFilter, commentsProps])

	useEffect(() => {
		setStarFilter(0)
		setPropertiesFilters(defaultPropertiesFilter)
		setOffset(3)
	}, [commentsProps])

	/* Функция для показа большего кол-во комментариев */
	const handleMoreComments = () => {
		setOffset((prev) => prev + 3)
	}

	return (
		<div className={style.reviews}>
			<h2 className={style.reviews__title}>Отзывы</h2>

			<ReviewsFilters
				updateStarsFilters={updateStarFilter}
				updatePropertiesFilters={updatePropertiesFilter}
				comments={commentsProps}
				defaultPropertiesFilter={propertiesFilters}
			/>

			<ul
				className={classNames(style.reviews__list, {
					[style['reviews__list--border']]: isNotEmpty,
				})}
			>
				{isNotEmpty ? (
					comments
						.slice(0, offset)
						.map((comment) => <Comment key={comment.id} data={comment} />)
				) : (
					<p className={style.reviews__listTitle}>Комментариев не найдено</p>
				)}
			</ul>

			<div className={style.reviews__buttonWrapper}>
				{!isOffsetOverLength && (
					<Button
						size='large'
						text='Показать больше'
						variant='green'
						onClick={handleMoreComments}
					/>
				)}
			</div>
		</div>
	)
}
