import { FC, ReactElement } from 'react'

import style from './style.module.scss'
import classNames from 'classnames'

type Props = {
	className?: string
	children: ReactElement | ReactElement[]
}

export const ContainerUI: FC<Props> = ({ children, className }) => (
	<div
		className={classNames(style.container, {
			[`${className}`]: className,
		})}
	>
		{children}
	</div>
)
