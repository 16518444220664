import { createBrowserRouter } from 'react-router-dom'

import { Home } from 'src/pages/Home'
import { Models } from 'src/pages/Models'
import { CONFIG_ROUTE_PATHS } from 'src/config'

export const router = createBrowserRouter(
	[
		{
			path: CONFIG_ROUTE_PATHS.HOME,
			element: <Home />,
		},
		{
			path: `${CONFIG_ROUTE_PATHS.MODELS}/:model`,
			element: <Models />,
		},
	],
	{ basename: '/' }
)
