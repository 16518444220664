export const CONFIG_ROUTE_PATHS = {
	HOME: '/',
	MODELS: '/models',
}

export const FIREBASE_CONFIG = {
	apiKey: process.env.FIREBASE_API_KEY,
	authDomain: 'opengarage-36859.firebaseapp.com',
	projectId: 'opengarage-36859',
	storageBucket: 'opengarage-36859.firebasestorage.app',
	messagingSenderId: '378743608756',
	appId: '1:378743608756:web:31f63503944bd21cf24ee1',
	measurementId: 'G-4N01EGL3NG',
}
