import { FC } from 'react'

import { TCard } from 'src/types'
import { ContainerUI } from 'src/components/UiKit/Container'
import { SliderCarsCards } from 'src/components/UiKit/SliderCarsCards'

import style from './style.module.scss'

type Props = {
	data: TCard[]
}

export const OtherCars: FC<Props> = ({ data }) => (
	<ContainerUI className={style.otherCars}>
		<h2 className={style.otherCars__title}>
			Другие автомобили в этой категории
		</h2>
		<SliderCarsCards
			slidesToShow={4}
			slidesToScroll={1}
			cards={data}
			clip={true}
		/>
	</ContainerUI>
)
