import { initializeApp } from 'firebase/app'
import { FC } from 'react'
import { RouterProvider } from 'react-router-dom'
import { AppDataContext } from 'src/components/AppDataProvider/AppDataProvider'
import { FIREBASE_CONFIG } from 'src/config'
import { router } from 'src/services/router'
import { getFirestore } from 'firebase/firestore'

const app = initializeApp(FIREBASE_CONFIG)

const firestore = getFirestore(app)

export const App: FC = () => (
	<AppDataContext.Provider value={{ firestore }}>
		<RouterProvider router={router} />
	</AppDataContext.Provider>
)
