import { FC, FormEvent, useEffect, useRef, useState } from 'react'

import { useMediaQuery } from 'react-responsive'

import { Tag } from 'src/components/UiKit/Tag'
import { RangeDouble } from 'src/components/UiKit/RangeDouble'
import { Search } from 'src/components/UiKit/Search'
import { Button } from 'src/components/UiKit/Button'
import { Dropdown } from 'src/components/UiKit/Dropdown'
import { Horizontal } from 'src/components/UiKit/Divider'
import { ReactComponent as PlusIcon } from 'src/assets/lightPlus.svg'
import { ReactComponent as LineIcon } from 'src/assets/lightLine.svg'

import style from './style.module.scss'
import { TFilterCars } from 'src/types'

type Props = {
	brands: string[]
	models: string[]
	bodys: string[]
	types: string[]
	handleFilterCars: (filters: TFilterCars) => void
	handleFiltersChange: (filters: TFilterCars) => void
}

export const Filters: FC<Props> = ({
	handleFilterCars,
	handleFiltersChange,
	bodys,
	brands,
	models,
	types,
}) => {
	const [formState, setFormState] = useState({
		brand: undefined,
		model: undefined,
		body: undefined,
		type: undefined,
	})

	const [searchState, setSearchState] = useState<string>('')
	const [ratingRangeState, setRatingRangeState] = useState<[number, number]>([
		0, 5,
	])

	const isDesktop = useMediaQuery({ minWidth: 768 })
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const contentRef = useRef<HTMLDivElement>(null)
	const buttonSize = isDesktop ? 'small' : 'large'

	/* Стили для анимации развертывания */
	const animatedStyles = isOpen
		? { maxHeight: contentRef.current?.scrollHeight }
		: { maxHeight: 0 }

	/* Функция обновления поля поиска */
	const handleSearchChange = (value: string) => {
		setSearchState(value)
	}

	/* Функция добавления выбранного фильтра */
	const handleAddTag = (value: string | undefined, id: string) => {
		setFormState((prev) => ({ ...prev, [id]: value }))
	}

	/* Функция обновления промежутка рейтинга */
	const handleChangeRatingRange = (values: [number, number]) => {
		setRatingRangeState(values)
	}

	/* Функция удаления тега */
	const handleDeleteTag = (id: string) => {
		setFormState((prev) => ({ ...prev, [id]: undefined }))
	}

	/* Функция подтверждения формы */
	const handleSubmit = (e: FormEvent) => {
		e.preventDefault()

		handleFilterCars({
			...formState,
			ratingRange: ratingRangeState,
			search: searchState,
		})
	}

	/* Функция отчистки формы */
	const handleClearForm = () => {
		setFormState({
			brand: undefined,
			model: undefined,
			body: undefined,
			type: undefined,
		})
		setRatingRangeState([0, 5])
		setSearchState('')
	}

	useEffect(() => {
		handleFiltersChange({
			...formState,
			ratingRange: ratingRangeState,
			search: searchState,
		})
	}, [formState])

	return (
		<div className={style.filters}>
			<div className={style.filters__head}>
				<span className={style.filters__title}>Фильтры</span>

				<button
					className={style.filters__button}
					onClick={() => setIsOpen((prev) => !prev)}
					id='filters-open-button'
					data-status={isOpen ? 'open' : 'close'}
				>
					{isOpen ? <LineIcon /> : <PlusIcon />}
				</button>
			</div>

			<div
				className={style.filters__contentWrapper}
				style={animatedStyles}
				ref={contentRef}
			>
				<div className={style.filters__content}>
					<form className={style.filters__form} onSubmit={handleSubmit}>
						<div>
							<Search onChange={handleSearchChange} value={searchState} />
							<Horizontal />
						</div>

						<div className={style.filters__parameters}>
							<Dropdown
								title='Марка'
								options={brands}
								onChange={handleAddTag}
								id='brand'
								value={formState.brand}
								error={undefined}
							/>
							<Dropdown
								title='Модель'
								options={models}
								onChange={handleAddTag}
								id='model'
								value={formState.model}
								error={undefined}
							/>
							<Dropdown
								title='Кузов'
								options={bodys}
								onChange={handleAddTag}
								id='body'
								value={formState.body}
								error={undefined}
							/>
							<Dropdown
								title='Тип'
								options={types}
								onChange={handleAddTag}
								id='type'
								value={formState.type}
								error={undefined}
							/>
						</div>

						<RangeDouble
							className={style.filters__range}
							label='Рейтинг'
							defaultValues={ratingRangeState}
							onChange={handleChangeRatingRange}
						/>

						<div className={style.filters__tags}>
							{isDesktop &&
								Object.entries(formState).map((tag, index) => {
									const [id, value] = tag

									if (value)
										return (
											<Tag
												key={index}
												title={value}
												id={id}
												onDelete={handleDeleteTag}
											/>
										)
								})}
						</div>

						{isDesktop && <Horizontal />}

						<div className={style.filters__buttons}>
							<div>
								<Button
									text='Очистить'
									variant='black'
									size={buttonSize}
									onClick={handleClearForm}
									type='button'
								/>
							</div>
							<div>
								<Button
									text='Применить'
									variant='green'
									size={buttonSize}
									type='submit'
									id='modelsFilters-submit-button'
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}
