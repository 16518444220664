import { FC } from 'react'

import { Stars } from 'src/components/UiKit/Stars'
import { formatNumber } from 'src/utils/formatNumber'
import { ContainerUI } from 'src/components/UiKit/Container'
import { ReactComponent as DottedPathIcon } from 'src/assets/dottedPath.svg'

import style from './style.module.scss'

type Props = {
	rating: number
}

export const RatingCar: FC<Props> = ({ rating }) => {
	return (
		<ContainerUI className={style.ratingCar}>
			<div className={style.ratingCar__header}>
				<h2 className={style.ratingCar__title}>Рейтинг</h2>

				<div className={style.ratingCar__ratingWrapper}>
					<Stars className={style.ratingCar__stars} rating={rating} />
					<DottedPathIcon className={style.ratingCar__dottedIcon} />
					<span className={style.ratingCar__rating}>
						{formatNumber(rating)}
					</span>
				</div>
			</div>

			<p className={style.ratingCar__description}>
				{rating >= 4 && 'Отлчичный рейтинг!'}
			</p>
		</ContainerUI>
	)
}
