import { FC, HTMLAttributes, MouseEvent } from 'react'

import { useNavigate } from 'react-router-dom'

type Props = HTMLAttributes<HTMLAnchorElement> & {
	to: string
	anchor: string
	children: string
	onClick?: () => void
}

export const HashLink: FC<Props> = ({
	anchor,
	to,
	children,
	onClick,
	...args
}) => {
	const navigate = useNavigate()

	const handleClick = (e: MouseEvent) => {
		e.preventDefault()
		navigate(to)

		setTimeout(() => {
			window.location.hash = anchor
		}, 100)

		if (onClick) {
			onClick() // Выполняем обработчик клика из пропсов
		}
	}

	return (
		<a href={`/${anchor}`} onClick={handleClick} {...args}>
			{children}
		</a>
	)
}
