import { FC } from 'react'

import classNames from 'classnames'

import { ReactComponent as SearchIcon } from 'src/assets/search.svg'

import style from './style.module.scss'

type Props = {
	onChange: (value: string) => void
	className?: string
	value: string
}

export const Search: FC<Props> = ({ className, onChange, value, ...args }) => (
	<div className={style.search}>
		<SearchIcon className={style.search__icon} />
		<input
			className={classNames(style.search__input, {
				[`${className}`]: className,
			})}
			type='search'
			placeholder='Поиск'
			value={value}
			onChange={(e) => onChange(e.target.value)}
			{...args}
		/>
	</div>
)
