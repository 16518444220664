import { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import { TCarScores } from 'src/types'
import style from './style.module.scss'

type PropertiesBarProps = {
	name: string
	setCurrentIndex: (t: number) => void
	currentIndex: number
}

const PropertiesBar: FC<PropertiesBarProps> = ({
	currentIndex,
	setCurrentIndex,
	name,
}) => {
	const buttons = Array(5).fill(0)

	/* Если индекс нажатой кнопки равен выбранному индексу то сбрасываем выбор */
	/* иначе устанавливаем выбранный индекс */
	const onClickButton = (index: number) => {
		if (index === currentIndex) {
			setCurrentIndex(0)
		} else {
			setCurrentIndex(index)
		}
	}

	return (
		<div className={style.propertiesBar}>
			<span>{name}</span>

			<div className={style.propertiesBar__list}>
				{buttons.map((_, index) => (
					<button
						key={index + 1}
						onClick={() => onClickButton(index + 1)}
						className={classNames(style.propertiesBar__button, {
							[style['propertiesBar__button--active']]:
								index + 1 <= currentIndex,
						})}
					/>
				))}
			</div>
		</div>
	)
}

type Props = {
	updatePropertiesFilters: (t: Partial<TCarScores>) => void
	defaultPropertiesFilters: TCarScores
}

export const FilterProperties: FC<Props> = ({
	updatePropertiesFilters,
	defaultPropertiesFilters,
}) => {
	const [state, setState] = useState(defaultPropertiesFilters)

	const onChange = (keyName: keyof TCarScores, value: number) => {
		setState((prev) => ({ ...prev, [keyName]: value }))
		updatePropertiesFilters({ [keyName]: value })
	}

	useEffect(() => {
		setState(defaultPropertiesFilters)
	}, [defaultPropertiesFilters])

	return (
		<div className={style.filterProperties}>
			<ul className={style.filterProperties__list}>
				<li>
					<PropertiesBar
						name='Надежность'
						currentIndex={state.reliability}
						setCurrentIndex={(t) => onChange('reliability', t)}
					/>
				</li>
				<li>
					<PropertiesBar
						name='Управляемость'
						currentIndex={state.manageability}
						setCurrentIndex={(t) => onChange('manageability', t)}
					/>
				</li>
				<li>
					<PropertiesBar
						name='Комфорт'
						currentIndex={state.comfort}
						setCurrentIndex={(t) => onChange('comfort', t)}
					/>
				</li>
				<li>
					<PropertiesBar
						name='Оборудованность'
						currentIndex={state.equipment}
						setCurrentIndex={(t) => onChange('equipment', t)}
					/>
				</li>
				<li>
					<PropertiesBar
						name='Премиальность'
						currentIndex={state.premium}
						setCurrentIndex={(t) => onChange('premium', t)}
					/>
				</li>
				<li>
					<PropertiesBar
						name='Экономичность'
						currentIndex={state.costEffectiveness}
						setCurrentIndex={(t) => onChange('costEffectiveness', t)}
					/>
				</li>
			</ul>
		</div>
	)
}
