import { FC, useRef } from 'react'

import Slider from 'react-slick'

import { TCard } from 'src/types'
import { Card } from 'src/components/UiKit/Card'

import style from './style.module.scss'
import classNames from 'classnames'

type Props = {
	cards: TCard[]
	slidesToShow: number
	slidesToScroll: number
	clip?: boolean
}

export const SliderCarsCards: FC<Props> = ({
	cards,
	slidesToShow,
	slidesToScroll,
	clip,
}) => {
	const sliderRef = useRef<Slider | null>(null)

	const settings = {
		slidesToShow: slidesToShow,
		slidesToScroll: slidesToScroll,
		adaptiveHeight: true,
		swipeToSlide: true,
		speed: 300,
		touchThreshold: 10,
		arrows: false,
		swipe: true,
		infinite: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
				},
			},
		],
	}

	return (
		<div
			className={classNames(style.sliderCardsCars, {
				[style['sliderCardsCars--clip']]: clip,
			})}
		>
			<Slider ref={sliderRef} {...settings}>
				{cards.map((card) => (
					<div className={style.sliderCardsCars__slide} key={card.id}>
						<Card data={card} />
					</div>
				))}
			</Slider>
		</div>
	)
}
