import { FC, ReactNode } from 'react'

import style from './style.module.scss'
import classNames from 'classnames'

type Props = {
	className?: string
	children: ReactNode
}

/* Базовый layout для страниц */
export const MainLayout: FC<Props> = ({ children, className }) => (
	<div
		className={classNames(style.page, {
			[`${className}`]: className,
		})}
	>
		{children}
	</div>
)
