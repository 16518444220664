import { CSSProperties, FC, useEffect, useState } from 'react'

import Slider from 'react-slider'
import classnames from 'classnames'

import { Rating } from 'src/components/UiKit/Rating'

import style from './style.module.scss'

type Props = {
	label: string
	className?: string
	onChange?: (values: [number, number]) => void
	defaultValues: number[]
}

type ThumbProps = {
	// eslint-disable-next-line
	props: any
	state: {
		index: number
		value: number[]
		valueNow: number
	}
}

type TrackProps = {
	// eslint-disable-next-line
	props: any
	state: {
		index: number
		value: number[]
	}
}

/* Функция которая удаляет из spread-а key. */
// eslint-disable-next-line
const removeKeyFromProps = (props: any) => {
	const { key, ...rest } = props
	void key
	return rest
}

/* Ползунок слайдера */
const Thumb: FC<ThumbProps> = ({ props, state }) => {
	const [min, max] = state.value

	const isRightThumb = state.index === 1
	const valueRange = max - min

	const styleLeftRange =
		valueRange <= 2 && min !== 0 ? 'translateX(-120%)' : undefined

	const styleRightRange =
		valueRange <= 2 && max !== 5 ? 'translateX(20%)' : 'translateX(-100%)'

	/* Стили для отображения рейтинга над ползунком */
	const styles: CSSProperties = {
		left: `${(state.valueNow / 5) * 100}%`,
		transform: isRightThumb ? styleRightRange : styleLeftRange,
		opacity: min !== 0 || max !== 5 ? 1 : 0,
	}

	return (
		<div>
			<Rating
				className={style.range__rating}
				rating={state.valueNow}
				style={styles}
			/>
			<div {...removeKeyFromProps(props)} />
		</div>
	)
}

/* Трек слайдера */
const Track: FC<TrackProps> = ({ props, state }) => {
	const [min, max] = state.value

	/* Стили трека */
	const styles: CSSProperties = {
		left: `${(min / 5) * 100}%`,
		width: `${((max - min) / 5) * 100}%`,
		backgroundColor: min !== 0 || max !== 5 ? '#14362f' : 'transparent',
	}

	return <div {...removeKeyFromProps(props)} style={styles} />
}

/* Двойной слайдер */
export const RangeDouble: FC<Props> = ({
	label,
	className,
	onChange,
	defaultValues,
}) => {
	const [minValue, setMinValue] = useState(defaultValues[0])
	const [maxValue, setMaxValue] = useState(defaultValues[1])

	const handleSliderChange = (values: [number, number]) => {
		setMinValue(values[0])
		setMaxValue(values[1])

		if (onChange) {
			onChange(values)
		}
	}

	/* Если начальные данные были изменены то устанавливаем их (нужно для сброса значений) */
	useEffect(() => {
		setMinValue(defaultValues[0])
		setMaxValue(defaultValues[1])
	}, [defaultValues])

	return (
		<div
			className={classnames(style.range__wrapper, {
				[`${className}`]: className,
			})}
		>
			<span className={style.range__label}>{label}:</span>

			<div className={style.range__values}>
				<span>0</span>
				<span>5</span>
			</div>

			<Slider
				className={style.range__slider}
				max={5}
				min={0}
				minDistance={1}
				onChange={handleSliderChange}
				thumbClassName={style.range__thumb}
				trackClassName={style.range__track}
				value={[minValue, maxValue]}
				renderThumb={(props, state) => (
					<Thumb key={state.index} props={props} state={state} />
				)}
				renderTrack={(props, state) => (
					<Track key={state.index} props={props} state={state} />
				)}
			/>
		</div>
	)
}
