import { FC } from 'react'

import { TCarScores } from 'src/types'
import { ContainerUI } from 'src/components/UiKit/Container'
import { RatingBand } from 'src/components/UiKit/RatingBand'

import style from './style.module.scss'

type Props = {
	scores: TCarScores
}

export const ScoresCar: FC<Props> = ({ scores }) => {
	const {
		comfort,
		costEffectiveness,
		equipment,
		manageability,
		premium,
		reliability,
	} = scores

	return (
		<ContainerUI className={style.scoresCar}>
			<div className={style.scoresCar__column}>
				<RatingBand property='Надежность' rating={reliability} />
				<RatingBand property='Управляемость' rating={manageability} />
				<RatingBand property='Комфорт' rating={comfort} />
			</div>

			<div className={style.scoresCar__column}>
				<RatingBand property='Оборудованность' rating={equipment} />
				<RatingBand property='Премиальность' rating={premium} />
				<RatingBand property='Экономичность' rating={costEffectiveness} />
			</div>
		</ContainerUI>
	)
}
