import { FC } from 'react'

import mockQuestions from 'src/mocks/question.json'
import { Accordion } from 'src/components/UiKit/Accordion'

import style from './style.module.scss'

export const Faq: FC = () => (
	<div className={style.faq}>
		<h2 className={style.faq__title}>FAQ</h2>

		<div className={style.faq__content}>
			{mockQuestions.map((question) => (
				<Accordion key={question.id} data={question} />
			))}
		</div>
	</div>
)
