import classNames from 'classnames'
import { CSSProperties, FC } from 'react'

import { ReactComponent as ArrowIcon } from 'src/assets/downArrow.svg'

import styles from './style.module.scss'

type PropsArrow = {
	className?: string
	style?: CSSProperties
	onClick?: () => void
	direction?: string
}

export const CustomArrow: FC<PropsArrow> = ({
	className,
	style,
	onClick,
	direction,
}) => (
	<button
		className={classNames(styles.customArrow, {
			[`${className}`]: className,
			[styles['customArrow--prev']]: direction === 'prev',
			[styles['customArrow--next']]: direction === 'next',
		})}
		style={{ ...style, display: 'block' }}
		onClick={onClick}
	>
		<ArrowIcon
			className={classNames(styles.customArrow__icon, {
				[styles['customArrow__icon--prev']]: direction === 'prev',
				[styles['customArrow__icon--next']]: direction === 'next',
			})}
		/>
	</button>
)
