import { forwardRef, HTMLAttributes } from 'react'

import { FieldError } from 'react-hook-form'

import { ErrorMessage } from 'src/components/UiKit/ErrorMessage'

import style from './style.module.scss'

type Props = HTMLAttributes<HTMLInputElement> & {
	labelText: string
	error: FieldError | undefined
	id: string
}

export const InputText = forwardRef<HTMLInputElement, Props>(
	({ labelText, error, id, ...args }, ref) => (
		<div className={style.inputText}>
			<input
				ref={ref}
				className={style.inputText__input}
				placeholder=' '
				type='text'
				id={id}
				{...args}
			/>
			<label className={style.inputText__label} htmlFor={id}>
				{labelText}
			</label>

			<ErrorMessage className={style.inputText__error} error={error} />
		</div>
	)
)

InputText.displayName = 'InputText'
