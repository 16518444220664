import { FC, useState } from 'react'

import classNames from 'classnames'

import { TCarParameter } from 'src/types'
import { Button } from 'src/components/UiKit/Button'
import { ContainerUI } from 'src/components/UiKit/Container'
import { CarSpecs } from 'src/components/UiKit/CarSpecs'

import style from './style.module.scss'

type Props = {
	data: TCarParameter[]
}

export const CharCar: FC<Props> = ({ data }) => {
	const [offset, setOffset] = useState(6)
	const charLength = data.length
	const isOverflowItems = charLength > 6
	const isOverOffset = offset >= charLength

	return (
		<div
			className={classNames(style.charCar, {
				[style['charCar--overflow-items']]: isOverflowItems,
			})}
		>
			<ContainerUI
				className={classNames(style.charCar__content, {
					[style['charCar__content--overflow-items']]: isOverflowItems,
				})}
			>
				<ul
					className={classNames(style.charCar__list, {
						[style['charCar__list--overflow-items']]: charLength >= 6,
					})}
				>
					{data.slice(0, offset).map((char, index) => (
						<li key={index} className={style.charCar__item}>
							<CarSpecs variant={char.type} text={char.text} />
						</li>
					))}
				</ul>

				<div>
					{isOverflowItems && !isOverOffset && (
						<Button
							size='large'
							text='Показать больше'
							variant='lightGreen'
							onClick={() => setOffset((prev) => prev + 3)}
						/>
					)}
				</div>
			</ContainerUI>
		</div>
	)
}
