import { FC } from 'react'

import mockSlider from 'src/mocks/slider.json'
import { LinkUI } from 'src/components/UiKit/Link'
import heroCarsImage from 'src/assets/heroCars.png'
import { Slider } from 'src/components/UiKit/Slider'
import { Button } from 'src/components/UiKit/Button'

import style from './style.module.scss'

type Props = {
	handleOpenModal: () => void
}

export const Hero: FC<Props> = ({ handleOpenModal }) => (
	<div className={style.hero}>
		<img className={style.hero__image} src={heroCarsImage} alt='Машины' />
		<div className={style.hero__circle} />

		<div className={style.hero__text}>
			<div className={style.hero__titles}>
				<h1 className={style.hero__heading}>Открытый гараж</h1>
				<h2 className={style.hero__subtitle}>Авто глазами тех, кто за рулём</h2>
			</div>
			<div className={style.hero__buttons}>
				<LinkUI to='#car-models' text='Модели' variant='green' size='large' />
				<Button
					text='Оставить отзыв'
					variant='black'
					size='large'
					onClick={handleOpenModal}
				/>
			</div>
		</div>

		<Slider slides={mockSlider} />
	</div>
)
